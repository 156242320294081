<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Logs</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 230"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.user="{ item }">
                                {{ item.user.id }} - {{ item.user.name }}
                            </template>
                            <template v-slot:item.empresa="{ item }">
                                {{ item.empresa.id }} - {{ item.empresa.fantasia }}
                            </template>
                            <template v-slot:item.payload="{ item }">
                                {{ item.payload }}
                            </template>
                        </v-data-table>
                        <Paginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';

export default {
    name: 'Logs',

    components: {
        BaseContainer,
        Paginate,
    },

    data: () => ({
        search: '',
        visible: false,
        loading: false,
        dados: [],
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Usuário', value: 'user' },
            { text: 'Empresa', value: 'empresa' },
            { text: 'Info', value: 'payload' },
            { text: 'Data/hora', value: 'created_at' },
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            async handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];
            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const params = `page=${this.pagination.current_page}&q=${this.search || ''}${sortBy}${sortOrder}`;

            this.$http.get(`zaper-control-logs?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },
    },
}
</script>
